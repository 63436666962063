import axios from 'axios'
import {
    GET_ALL_COURSES_REQUEST,
    GET_ALL_COURSES_SUCCESS,
    GET_ALL_COURSES_FAILED,
    GET_ALL_MODULE_REQUEST,
    GET_ALL_MODULE_SUCCESS,
    GET_ALL_MODULE_FAILED,
    GET_SINGLE_COURSE_REQUEST,
    GET_SINGLE_COURSE_SUCCESS,
    GET_SINGLE_COURSE_FAILED,
    GET_ALL_CHAPTER_REQUEST,
    GET_ALL_CHAPTER_SUCCESS,
    GET_ALL_CHAPTER_FAILED,
    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAILED,
    CREATE_CHAPTER_REQUEST,
    CREATE_CHAPTER_SUCCESS,
    CREATE_CHAPTER_FAILED,
    CREATE_MODULE_REQUEST,
    CREATE_MODULE_SUCCESS,
    CREATE_MODULE_FAILED,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILED,
    DELETE_CHAPTER_REQUEST,
    DELETE_CHAPTER_SUCCESS,
    DELETE_CHAPTER_FAILED,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILED,
    UPDATE_CHAPTER_REQUEST,
    UPDATE_CHAPTER_SUCCESS,
    UPDATE_CHAPTER_FAILED,
} from '../constants/courseConstants'

const API = 'http://localhost:5000'

//CREATE COURSE
export const createNewCourse = (courseName, courseCategory, courseDescription, featureImage, difficulty, instructorName, price, metaDescription, duration, metaKeywords,info1,info2,info3,info4,perk) => async (dispatch) => {
   
    try {
        dispatch({

            type: CREATE_COURSE_REQUEST

        })
        const config = {
            'Content-Type': 'application/json'
        }
        const body = {
            courseName, courseCategory, courseDescription, featureImage, difficulty, instructorName, price, metaDescription, duration, metaKeywords,info1,info2,info3,info4,perk
        }
        const { data } = await axios.post(
            `${API}/private/courses/new-course`,
            body,
            config
        )
        dispatch({
            type: CREATE_COURSE_SUCCESS,
            payload: data
        })

    }
    catch (error) {
        dispatch({
            type: CREATE_COURSE_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }
}
//CREATE COURSE
export const createNewModule = (title, course, courseSlug) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_MODULE_REQUEST

        })
        const config = {
            'Content-Type': 'application/json'
        }
        const body = {
            title,
            course
        }
        const { data } = await axios.post(
            `${API}/private/courses/${courseSlug}/module/new`,

            body,
            config
        )
        dispatch({
            type: CREATE_MODULE_SUCCESS,
            payload: data
        })

    }
    catch (error) {
        dispatch({
            type: CREATE_MODULE_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }
}
//CREATE CHAPTER
export const createNewChapter = (title, module, video_link, moduleSlug, courseSlug) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_CHAPTER_REQUEST

        })
        const config = {
            'Content-Type': 'application/json'
        }
        const body = {
            title,
            module: module,
            video_link,
        }
        const { data } = await axios.post(
            `${API}/private/courses/${courseSlug}/module/${moduleSlug}/chapter/new`,
            body,
            config
        )
        dispatch({
            type: CREATE_CHAPTER_SUCCESS,
            payload: data
        })

    }
    catch (error) {
        dispatch({
            type: CREATE_CHAPTER_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })

    }
}



// GET ALL COURSES
export const getAllCourse = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_COURSES_REQUEST
        })

        const config = {
            'Content-Type': 'application/json'
        }
        const { data } = await axios.get(
            `${API}/private/courses`,
            config
        )
        dispatch({
            type: GET_ALL_COURSES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_COURSES_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}


// GET ALL MODULES
export const getAllModule = (courseSlug) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_MODULE_REQUEST
        })

        const config = {
            'Content-Type': 'application/json'
        }

        const { data } = await axios.get(
            `${API}/private/courses/${courseSlug}/modules`,
            config
        )
        dispatch({
            type: GET_ALL_MODULE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_MODULE_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}
// GET ALL CHAPTER
export const getAllChapter = (courseSlug, moduleSlug) => async (dispatch) => {
    try {
        dispatch({
            type: GET_ALL_CHAPTER_REQUEST
        })

        const config = {
            'Content-Type': 'application/json'
        }

        const { data } = await axios.get(
            `${API}/private/courses/${courseSlug}/module/${moduleSlug}/chapters`,
            config
        )
        dispatch({
            type: GET_ALL_CHAPTER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_CHAPTER_FAILED,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
        })
    }
}

// GET SINGLE  Chapter
export const getSingleCourseChapter =
    (chapterSlug, moduleSlug) => async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_SINGLE_COURSE_REQUEST
            })

            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.get(
                `${API}/private/courses/module/${moduleSlug}/chapter/${chapterSlug}`,
                config
            )
            dispatch({
                type: GET_SINGLE_COURSE_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: GET_SINGLE_COURSE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
            })
        }
    }
// UPDATE SINGLE  COURSE
export const updateChapter =
    (title, moduleSlug, chapterSlug, video_link) => async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_CHAPTER_REQUEST
            })
            const body = {
                title,
                moduleSlug,
                chapterSlug,
                video_link
            }
            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.put(
                `${API}/private/courses/module/${moduleSlug}/chapter/${chapterSlug}`, body,
                config
            )
            dispatch({
                type: UPDATE_CHAPTER_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: UPDATE_CHAPTER_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
            })
        }
    }
// DELETE SINGLE  COURSE
export const deleteCourse =
    (courseSlug) => async (dispatch, getState) => {
        try {
            dispatch({
                type: DELETE_COURSE_REQUEST
            })

            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.delete(
                `${API}/private/courses/${courseSlug}`,
                config
            )
            dispatch({
                type: DELETE_COURSE_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: DELETE_COURSE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
            })
        }
    }
// DELETE SINGLE  MODULE
export const deleteModule =
    (moduleSlug) => async (dispatch, getState) => {
        try {
            dispatch({
                type: DELETE_MODULE_REQUEST
            })

            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.delete(
                `${API}/private/courses/modules/${moduleSlug}`,
                config
            )
            dispatch({
                type: DELETE_MODULE_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: DELETE_MODULE_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
            })
        }
    }
// DELETE SINGLE  CHAPTER
export const deleteChapter =
    (moduleSlug, chapterSlug) => async (dispatch, getState) => {
        try {
            dispatch({
                type: DELETE_CHAPTER_REQUEST
            })

            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.delete(
                `${API}/private/courses/modules/${moduleSlug}/chapters/${chapterSlug}`,
                config
            )
            dispatch({
                type: DELETE_CHAPTER_SUCCESS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: DELETE_CHAPTER_FAILED,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
            })
        }
    }
