import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom"
//IMPORT REDUX
import { createNewChapter ,deleteChapter,updateChapter,getAllChapter} from '../../redux/actions/courseAction'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux'
export const ChapterLogic = () => {
    const dispatch = useDispatch()
    const API = 'http://localhost:5000'
    const [open, setOpen] = useState(false);
    const[edit,setEdit] =useState(false);
    const {courseName, moduleName,id} = useParams();
    const [categories, setCategories] = useState([])
    const [link, setLink] = useState('');
    const [title, setTitle] = useState('')

    const { courses } = useSelector((state) => state.getAllCourse)
    const { error } = useSelector((state) => state.createNewChapter)
    const {chapter} = useSelector((state)=>state.getAllChapter)


    useEffect(() => {
        const getCategories = async () => {
            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.get(`${API}/private/courses/category`, config)
            setCategories(data)
        }
        getCategories()
    }, [])
    const getYtEmbed = (link) =>{
        // validate URL
    const pattern = new RegExp('^(https?:\\/\\/)?'+ 
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
    '(\\#[-a-z\\d_]*)?$','i');
    
     if(pattern.test(link)){
            const URL = link.split("v=")[1].substring(0, 11);
            const VIDEOURL = `https://www.youtube.com/embed/${URL}`
            return VIDEOURL;
     }
     else{
         alert("this is not the correct link")
     }
}
    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }, [error])


    useEffect(() => {
        dispatch(getAllChapter(courseName,moduleName))
    }, [dispatch, courseName,moduleName])
//title, module,video_link,moduleSlug, courseSlug
    const createChapterHandler = () => {
        setOpen(false)
       const new_link= getYtEmbed(link)
        if (title) {
            dispatch(createNewChapter(title, id,new_link,moduleName, courseName))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
            })
        }
    }
    const deleteChapterHandler = async (chapterSlug)=>{
        dispatch(deleteChapter(moduleName,chapterSlug))
       }
    const updateChapterHandler = async (title,chapterSlug,link)=>{
        setEdit(false)
        const new_link= getYtEmbed(link)
        if(title!== '' && link!=='' ){
            dispatch(updateChapter(title,moduleName,chapterSlug,new_link,))
        }
        else{

        }
       }

    return {
        open,
        setOpen,
        courses,
        chapter,
        createChapterHandler,
        deleteChapterHandler,
        updateChapterHandler,
        categories,
        title,
        setTitle,
        link,
        setLink,
        edit,
        setEdit
    }
}
