///    CREATE COURSE  REDUCER    ///
import {
    GET_ALL_COURSES_REQUEST,
    GET_ALL_COURSES_SUCCESS,
    GET_ALL_COURSES_FAILED,
    GET_ALL_MODULE_REQUEST,
    GET_ALL_MODULE_SUCCESS,
    GET_ALL_MODULE_FAILED,
    GET_ALL_CHAPTER_REQUEST,
    GET_ALL_CHAPTER_SUCCESS,
    GET_ALL_CHAPTER_FAILED,
    CREATE_COURSE_REQUEST,
    CREATE_COURSE_SUCCESS,
    CREATE_COURSE_FAILED,
    CREATE_CHAPTER_REQUEST,
    CREATE_CHAPTER_SUCCESS,
    CREATE_CHAPTER_FAILED,
    CREATE_MODULE_REQUEST,
    CREATE_MODULE_SUCCESS,
    CREATE_MODULE_FAILED,
    UPDATE_CHAPTER_REQUEST,
    UPDATE_CHAPTER_SUCCESS,
    UPDATE_CHAPTER_FAILED,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_FAILED,
    DELETE_CHAPTER_REQUEST,
    DELETE_CHAPTER_SUCCESS,
    DELETE_CHAPTER_FAILED,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILED,
} from '../constants/courseConstants'




const createNewCourseInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    course: null
}
export const createNewCourseReducer = (state = createNewCourseInitialState, action) => {
    switch (action.type) {
        case CREATE_COURSE_REQUEST:
            return { ...state, loading: true }
        case CREATE_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                course: action.payload
            }
        case CREATE_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                course: null
            }
        default:
            return state
    }
}

const createNewModuleInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    module: null
}
export const createNewModuleReducer = (state = createNewModuleInitialState, action) => {
    switch (action.type) {
        case CREATE_MODULE_REQUEST:
            return { ...state, loading: true }
        case CREATE_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                module: action.payload
            }
        case CREATE_MODULE_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                module: null
            }
        default:
            return state
    }
}
const createNewChapterInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    chapter: null
}
export const createNewChapterReducer = (state = createNewChapterInitialState, action) => {
    switch (action.type) {
        case CREATE_CHAPTER_REQUEST:
            return { ...state, loading: true }
        case CREATE_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                chapter: action.payload
            }
        case CREATE_CHAPTER_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                chapter: null
            }
        default:
            return state
    }
}
const getAllCourseInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    courses: null
}
export const getAllCourseReducer = (state = getAllCourseInitialState, action) => {
    switch (action.type) {
        case GET_ALL_COURSES_REQUEST:
            return { ...state, loading: true }
        case GET_ALL_COURSES_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                courses: action.payload
            }
        case GET_ALL_COURSES_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                courses: null
            }
        default:
            return state
    }
}

const getAllModuleInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    modules: null
}
export const getAllModuleReducer = (state = getAllModuleInitialState, action) => {
    switch (action.type) {
        case GET_ALL_MODULE_REQUEST:
            return { ...state, loading: true }
        case GET_ALL_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                modules: action.payload
            }
        case GET_ALL_MODULE_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                modules: null
            }
        default:
            return state
    }
}
const getAllChapterInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    chapter: null
}
export const getAllChapterReducer = (state = getAllChapterInitialState, action) => {
    switch (action.type) {
        case GET_ALL_CHAPTER_REQUEST:
            return { ...state, loading: true }
        case GET_ALL_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                chapter: action.payload
            }
        case GET_ALL_CHAPTER_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                chapter: null
            }
        default:
            return state
    }
}

const updateChapterInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    chapter: null
}
export const updateChapterReducer = (state = updateChapterInitialState, action) => {
    switch (action.type) {
        case UPDATE_CHAPTER_REQUEST:
            return { ...state, loading: true }
        case UPDATE_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                chapter: action.payload
            }
        case UPDATE_CHAPTER_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                chapter: null
            }
        default:
            return state
    }
}
const deleteCourseInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    course: null
}
export const deleteCourseReducer = (state = deleteCourseInitialState, action) => {
    switch (action.type) {
        case DELETE_COURSE_REQUEST:
            return { ...state, loading: true }
        case DELETE_COURSE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                course: action.payload
            }
        case DELETE_COURSE_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                course: null
            }
        default:
            return state
    }
}

const deleteModuleInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    module: null
}
export const deleteModuleReducer = (state = deleteModuleInitialState, action) => {
    switch (action.type) {
        case DELETE_MODULE_REQUEST:
            return { ...state, loading: true }
        case DELETE_MODULE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                module: action.payload
            }
        case DELETE_MODULE_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                module: null
            }
        default:
            return state
    }
}

const deleteChapterInitialState = {
    loading: false,
    error: null,
    isAuthenticated: false,
    chapter: null
}
export const deleteChapterReducer = (state = deleteChapterInitialState, action) => {
    switch (action.type) {
        case DELETE_CHAPTER_REQUEST:
            return { ...state, loading: true }
        case DELETE_CHAPTER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                chapter: action.payload
            }
        case DELETE_CHAPTER_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: action.payload,
                chapter: null
            }
        default:
            return state
    }
}

