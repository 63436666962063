import axios from 'axios'
import {
  ADMIN_SIGNIN_REQUEST,
  ADMIN_SIGNIN_SUCCESS,
  ADMIN_SIGNIN_FAILED,
  ADMIN_LOGOUT,
  UPLOAD_IMAGE_TO_CLOUD_REQUEST,
  UPLOAD_IMAGE_TO_CLOUD_SUCCESS,
  UPLOAD_IMAGE_TO_CLOUD_FAILED,
} from '../constants/adminConstants'





const localStorage = window.localStorage
const API = 'http://localhost:5000'



// signIn user
export const signInUser = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_SIGNIN_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const body = {
      email,
      password
    }
    const { data } = await axios.post(`${API}/public/admin/login`, body, config)
    dispatch({
      type: ADMIN_SIGNIN_SUCCESS,
      payload: data
    })
    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: ADMIN_SIGNIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}



// user logout
export const logoutUser = () => (dispatch) => {
  if (localStorage.getItem('userInfo')) {
    localStorage.removeItem('userInfo')
  }
  dispatch({
    type: ADMIN_LOGOUT
  })
}


// upload image to cloud
export const uploadImageToCloud = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_IMAGE_TO_CLOUD_REQUEST
    })

    const config = {
      'Content-Type': 'application/json'
    }
    const { data } = await axios.post(`${API}/private/courses/upload-image`, formData, config)
    dispatch({
      type: UPLOAD_IMAGE_TO_CLOUD_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGE_TO_CLOUD_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}