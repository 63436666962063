import {
  ADMIN_SIGNIN_REQUEST,
  ADMIN_SIGNIN_SUCCESS,
  ADMIN_SIGNIN_FAILED,
  ADMIN_LOGOUT,
  UPLOAD_IMAGE_TO_CLOUD_REQUEST,
  UPLOAD_IMAGE_TO_CLOUD_SUCCESS,
  UPLOAD_IMAGE_TO_CLOUD_FAILED,
} from '../constants/adminConstants'




///    SIGNIN REDUCER    ///
export const userSigninReducer = (
  state = {
    loading: false,
    userInfo: null,
    error: null,
    isAuthenticated: false
  },
  action
) => {
  switch (action.type) {
    case ADMIN_SIGNIN_REQUEST:
      return {
        ...state,
        userInfo: null,
        error: null,
        isAuthenticated: false,
        loading: true
      }
    case ADMIN_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: null,
        userInfo: action.payload

      }
    case ADMIN_SIGNIN_FAILED:
      return {
        ...state,
        loading: false,
        userInfo: null,
        isAuthenticated: false,
        error: action.payload
      }

    case ADMIN_LOGOUT:
      return {
        userInfo: null
      }
    default:
      return state
  }
}


///    UPLOAD IMAGE TO CLOUDE REDCER    ///
export const uploadImageToCloudReducer = (
  state = {
    loading: false,
    data: null,
    error: null,
    isAuthenticated: false
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_IMAGE_TO_CLOUD_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        isAuthenticated: false,
        loading: true
      }
    case UPLOAD_IMAGE_TO_CLOUD_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: null,
        data: action.payload

      }
    case UPLOAD_IMAGE_TO_CLOUD_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        isAuthenticated: false,
        error: action.payload
      }
    default:
      return state
  }
}