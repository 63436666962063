import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ChapterInfo from "./components/ChapterInfo/ChapterInfo";
import Dashboard from "./components/Dashboard/Dashboard";
import CourseDetails from "./components/ModuleInfo/Moduleinfo";
import Login from './components/Login/Login'
import Navigation from './components/NavigationBar/Navigation'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navigation/>
        <Routes>
          <Route exact path="/sign-in" element={ <Login/> }/>
          <Route exact path="/" element={< Dashboard/>} />
          <Route exact path="/courses/:courseName/:id/modules" element={< CourseDetails />} />
          <Route exact path="/:courseName/module/:moduleName/chapter/:id" element={< ChapterInfo />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
