import React, { useState } from 'react'
// import Navigation from '../NavigationBar/Navigation'
import SideBar from '../SideBar/SideBar'
import './styles.css';
import { Button, Fade, Modal, Box, Backdrop } from '@mui/material';
import { Delete } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { ChapterLogic } from './ChapterLogic';
function ChapterInfo() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const {
        open,
        setOpen,
        edit,
        setEdit,
        createChapterHandler,
        chapter,
        deleteChapterHandler,
        updateChapterHandler,
        title,
        setTitle,
        link,
        setLink
    } = ChapterLogic();

    const [youtube, setyoutube] = useState(false);


    const handleEdit = () => setEdit(true);
    const handleEditNot = () => setEdit(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const check = (e) => {

        if (e.target.value === 'youtube')
            setyoutube(true);
        else
            setyoutube(false);

    }

    return (
        <div>
            <SideBar />
            <div className="chapter-info">
                <h1>Add Chapter content</h1>
                <div name="registration" className="registartion-form">
                    <table>
                        <tr>
                            <Button onClick={handleOpen}>+ Add Videos</Button>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={open}>
                                    <Box sx={style}>


                                        <div >
                                            <input type="text" name="video-name" placeholder="Video Title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                            <div className="form-group col-md-6">
                                                <label>Upload from Youtube or Your Device:</label><br />
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="video" id="inlineRadiom"
                                                        value="youtube" onClick={check} />
                                                    <label className="form-check-label" for="inlineRadiom">Youtube</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio"
                                                        name="video" id="inlineRadiof" value="device" onClick={check} />
                                                    <label className="form-check-label" for="inlineRadiof">Device</label>
                                                </div>
                                            </div>

                                            {!youtube ? <input type="file" accept="video/*" className='my-3' /> :
                                                <input type="text" value={link} onChange={(e) => { setLink(e.target.value) }} />
                                            }

                                            <button onClick={createChapterHandler} >Submit</button>
                                        </div>
                                    </Box>
                                </Fade>
                            </Modal>
                        </tr>
                        {chapter &&
                            chapter.body.map(
                                (currentChapter, index) => {
                                    return (
                                        <div className="chapters">
                                            <ul>

                                                <li> <div className="module1 my-3" key={index}  >
                                                    <div style={{ display: "flex" }} >
                                                        <h3>{currentChapter.title}</h3>
                                                        {/* delete */}
                                                        <Delete style={{ margin: "0.2rem 1rem 0rem 1rem", cursor: "pointer" }} onClick={() => {
                                                            deleteChapterHandler(
                                                                currentChapter.chapterSlug
                                                            )
                                                        }} />

                                                        {/* update */}

                                                        <EditIcon style={{ margin: "0.2rem 1rem 0rem 1rem", cursor: "pointer" }} onClick={handleEdit} />
                                                        <Modal
                                                            open={edit}
                                                            onClose={handleEditNot}
                                                            closeAfterTransition
                                                            BackdropComponent={Backdrop}
                                                            BackdropProps={{
                                                                timeout: 500,
                                                            }}
                                                        >
                                                            <Fade in={edit}>
                                                                <Box sx={style}>


                                                                    <div >
                                                                        <input type="text" name="video-name" placeholder="Video Title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                                                        <input type="text" value={link} onChange={(e) => { setLink(e.target.value) }} />
                                                                        <button onClick={() => {
                                                                            updateChapterHandler(
                                                                                title,
                                                                                currentChapter.chapterSlug,
                                                                                link
                                                                            )
                                                                        }} >Update</button>
                                                                    </div>
                                                                </Box>
                                                            </Fade>
                                                        </Modal>
                                                    </div>

                                                    <iframe width="350" height="250" src={currentChapter.video_link} title="youtube-video-player" ></iframe>
                                                </div></li>
                                            </ul>
                                        </div>
                                    );
                                })}

                    </table>
                </div>
            </div>
        </div>
    )
}

export default ChapterInfo
