import React from 'react'
// import Navigation from '../NavigationBar/Navigation'
import SideBar from '../SideBar/SideBar'
import './styles.css';
import { Delete } from '@mui/icons-material';

import { Button, Fade, Modal, Box, Backdrop } from '@mui/material';
import { ModuleLogic } from './module.Logic'
// import Typography from '@mui/material/Typography';
function CourseDetails() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const {
        open,
        setOpen,
        redirectHandler,
        deleteModuleHandler,
        title,
        modules,
        setTitle,
        createModuleHandler,
    } = ModuleLogic()

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            {/* <Navigation /> */}
            <SideBar />

            <div className="course-add">

                <div className="container3">
                    <h1>Add Modules</h1>
                    <div className="add-module-item">
                        <Button onClick={handleOpen}>+ Add Modules</Button>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={open}>
                                <Box sx={style}>
                                    <div>

                                        <div className="form-group ">
                                            <input type="text" className="inputs" name="module-name" style={{ height: "3rem" }} placeholder='module Name' value={title}
                                                onChange={(e) => { setTitle(e.target.value) }} />
                                        </div>

                                        <button onClick={createModuleHandler}>Add Module</button>
                                    </div>
                                </Box>
                            </Fade>
                        </Modal>
                    </div>
                    {modules &&
                        modules.body.map(
                            (currentModule, index) => {
                                return (
                                    <div className="modules">
                                        <ul>

                                            <li> <div className="module1 my-3" key={index} style={{ cursor: "pointer", display: "flex", alignItems: "center" }} >
                                                <h3 onClick={() => redirectHandler(currentModule.moduleSlug,
                                                    currentModule._id)} style={{ cursor: "pointer" }} >{currentModule.title}</h3>
                                                <Delete style={{ marginLeft: "1rem" }} onClick={() => {
                                                    deleteModuleHandler(
                                                        currentModule.moduleSlug

                                                    )
                                                }} />
                                            </div></li>
                                        </ul>
                                    </div>
                                );
                            })}
                </div>
            </div>



        </div>



    )
}

export default CourseDetails
