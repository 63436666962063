import { useState, useEffect } from 'react'
import axios from 'axios'

//IMPORT REDUX
import { getAllCourse, createNewCourse, deleteCourse } from '../../redux/actions/courseAction'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { uploadImageToCloud } from '../../redux/actions/adminAction';

export const DashboardLogic = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const API = 'http://localhost:5000'
    const [open, setOpen] = useState(false);
    const [preview, setPreview] = useState(false)
    const [input1, setInput1] = useState()
    const [input2, setInput2] = useState()
    const [input3, setInput3] = useState()
    const [input4, setInput4] = useState()
    const [perk,setPerk]= useState()

    const [categories, setCategories] = useState([])
    const [search, setSearch] = useState(null)
    const [courseName, setCoursename] = useState(null);
    const [desc, setDesc] = useState(null);
    const [difficulty, setDifficulty] = useState(null);
    const [category, setCategory] = useState(null);
    const [instructor, setInstructor] = useState(null);
    const [meta, setMeta] = useState(null);
    const [tags, setTags] = useState()
    const [duration, setDuration] = useState(null);
    const [img, setImg] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png");
    const [price, setPrice] = useState(null);
    const [priceOp, setPriceOp] = useState(null);
    const [show, setShow] = useState(false);




    const [fileData, setFileData] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    const { courses } = useSelector((state) => state.getAllCourse)
    const { error } = useSelector((state) => state.createNewCourse)
    const { data, loading } = useSelector((state) => state.uploadImageToCloud)


    useEffect(() => {
        const getCategories = async () => {
            const config = {
                'Content-Type': 'application/json'
            }
            const { data } = await axios.get(`${API}/private/courses/category`, config)
            setCategories(data)
        }
        getCategories()
    }, [])

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
        }
    }, [error])

    useEffect(() => {
        dispatch(getAllCourse(search))
    }, [dispatch, search])

    const deleteCourseHandler = async (courseName) => {
        dispatch(deleteCourse(courseName))
    }
    const handleChange = (e) => {
        setPriceOp(e.target.value)
        if (e.target.value === 'paid') {
            setShow(true)
            setPrice('')


        }
        else {
            setShow(false)
            setPrice('Free')
            console.log(price);
        }
    }
    const redirectHandler = async (courseSlug, id) => {

        navigate(`/courses/${courseSlug}/${id}/modules`)
    }



    const createCourseHandler = () => {
        // setOpen(false)
        if (courseName && category && desc && difficulty && instructor && isFileUploaded) {
            dispatch(createNewCourse(courseName, category, desc, img, difficulty, instructor, price, meta, duration, tags,input1,input2,input3,input4,perk))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
            })
        }
    }


    //IMAGE UPLOAD TO DO 

    const addFileToCloud = async (e) => {
        if (fileData.target.files.length > 0) {
            // Update UI to show file is uploading
            const file = e.target.files[0];
            //setting filename

            // Create FormData and pass picked file with other necessary details
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", "matricula-courses");



            var allowedExtensions =
                /(\.jpg|\.JPG|\.jpeg|\.JPEG|\.png|\.PNG)$/i;

            if (!allowedExtensions.exec(file.name)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Only Images are allowed',
                })
                return false;
            }
            else {
                dispatch(uploadImageToCloud(formData))
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a file',
            })
        }
    }


    useEffect(() => {
        if (data) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: "Image Uploaded Successfully",
            })
            setIsFileUploaded(true)
            setImg(data.data.Location)
        }
    }, [data])

    return {
        handleChange,
        open,
        setOpen,
        courses,
        createCourseHandler,
        redirectHandler,
        categories,
        img,
        setImg,
        search,
        setSearch,
        courseName,
        setCoursename,
        show,
        setShow,
        tags,
        setTags,
        desc,
        setDesc,
        difficulty,
        setDifficulty,
        category,
        setCategory,
        instructor,
        setInstructor,
        meta,
        setMeta,
        price,
        setPrice,
        priceOp,
        setPriceOp,
        duration,
        setDuration,
        preview,
        setPreview,
        deleteCourseHandler,
        fileData,
        setFileData,
        selectedFile,
        setSelectedFile,
        addFileToCloud,
        isFileUploaded,
        loading,
        input1,
        setInput1,
        input2,
        setInput2,
        input3,
        setInput3,
        input4,
        setInput4,
        perk,
        setPerk
    }
}
