import React from 'react'
import SideBar from '../SideBar/SideBar';
import { Button, Fade, Modal, Box, Backdrop, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { DashboardLogic } from './Dashboard.Logic'
import './styles.css'

export default function Dashboard() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70rem",
        height: "40rem",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: "1rem",
        overflow: "scroll"
    };
    const {
        handleChange,
        open,
        setOpen,
        courses,
        createCourseHandler,
        redirectHandler,
        // img,
        // setImg,
        courseName,
        setCoursename,
        show,
        tags,
        setTags,
        desc,
        setDesc,
        difficulty,
        setDifficulty,
        category,
        setCategory,
        instructor,
        setInstructor,
        meta,
        setMeta,
        price,
        setPrice,
        priceOp,
        duration,
        setDuration,
        preview,
        setPreview,
        img,
        deleteCourseHandler,
        fileData,
        setFileData,
        setSelectedFile,
        addFileToCloud,
        isFileUploaded,
        loading,
        input1,
        setInput1,
        input2,
        setInput2,
        input3,
        setInput3,
        input4,
        setInput4,
        perk,
        setPerk

    } = DashboardLogic();

    const handlePreview = (e) => {
        setPreview(!preview);
        e.preventDefault()
    };
    // const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <SideBar />

            <div className="add-course">
                <Button onClick={handleOpen}>Add Course</Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} >
                            <Typography id="transition-modal-title" variant="h4" component="h2">
                                Course Details
                            </Typography>

                            <div action="" className="form-elements bg-success p-2 text-dark bg-opacity-25">
                                <div className="form-arrange">
                                    <div className="input-group w-75 ">
                                        <input type="text" className="form-control" name="course-name" style={{ height: "3rem" }} placeholder='Course Name'
                                            value={courseName}
                                            onChange={(e) => { setCoursename(e.target.value) }} />
                                    </div>
                                    <div className="thumbnail  ">
                                        {/* <h2>Thumbnail</h2> */}
                                        {/* <p>Select or Upload a picture </p> */}
                                        <div>
                                            {
                                                !isFileUploaded ?
                                                    <>
                                                        <input type="file"
                                                            onChange={(e) => {
                                                                setFileData(e);
                                                                setSelectedFile(e.target.files[0].name);
                                                            }}
                                                            className="text-bg-info p-3" style={{ margin: "0rem 0rem 3rem 0rem" }} />
                                                        {
                                                            !loading ?
                                                                <input onClick={() => { addFileToCloud(fileData) }} type="button" value="Upload Image" className="btn btn-primary" />
                                                                :
                                                                <input type="button" value="Loading..." className="btn btn-primary" />
                                                        }
                                                    </>
                                                    :
                                                    <button onClick={handlePreview}>Preview</button>
                                            }
                                        </div>
                                        <Modal
                                            open={preview}
                                            onClose={handlePreview}
                                            closeAfterTransition
                                            BackdropComponent={Backdrop}
                                            BackdropProps={{
                                                timeout: 500,
                                            }}
                                        >
                                            <Fade
                                                in={preview}>
                                                <img
                                                    style={{ width: "50%", height: "50%", margin: "auto", display: "block", marginTop: "10rem" }}
                                                    src={img} alt="thumbanail" />

                                            </Fade></Modal>

                                    </div>
                                    <div className="input-group w-75">
                                        <input type="text" name="category" className="form-control" style={{ height: "3rem", width: "26rem" }} placeholder='Category'
                                            value={category}
                                            onChange={(e) => { setCategory(e.target.value) }} />
                                    </div>

                                    <div className="form-floating  w-75">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={desc}
                                            onChange={(e) => { setDesc(e.target.value) }} ></textarea>
                                        <label for="floatingTextarea2">Description</label>
                                    </div>

                                    <div className="input-group w-75 ">
                                        <input type="text" className="form-control" name="instructor-name" style={{ height: "3rem" }} placeholder='Instructor Name'
                                            value={instructor}
                                            onChange={(e) => { setInstructor(e.target.value) }} />
                                    </div>
                                    <div className="input-group w-75 my-3">
                                        <select className="form-select" id="inputGroupSelect01" value={difficulty} onChange={(e) => { setDifficulty(e.target.value) }}>
                                            <option selected>Select Difficulty</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Advanced">Advanced</option>
                                        </select>
                                    </div>
                                    <div className="input-group w-75 my-3">
                                        <select className="form-select" id="inputGroupSelect02" value={priceOp} onChange={handleChange}>
                                            <option selected>Select Price</option>
                                            <option value="free">Free</option>
                                            <option value="paid">Paid</option>
                                        </select>
                                    </div>
                                    {
                                        show ?
                                            <div className="input-group w-75 ">
                                                <input type="text" className="form-control" name="price" style={{ height: "3rem" }} placeholder='Enter Price'
                                                    value={price}
                                                    onChange={(e) => { setPrice(e.target.value) }} />
                                            </div>
                                            :
                                            ""
                                    }

                                    <div className="input-group w-75 ">
                                        <input type="text" className="form-control" name="tags" style={{ height: "3rem" }} placeholder='Tags'
                                            value={tags}
                                            onChange={(e) => { setTags(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={meta}
                                            onChange={(e) => { setMeta(e.target.value) }} />
                                        <label for="floatingTextarea2">Meta Description</label>

                                    </div>
                                    <div className="input-group w-75 ">
                                        <input type="number" className="form-control" name="duration" style={{ height: "3rem" }} placeholder='Duration(in hours)'
                                            value={duration}
                                            onChange={(e) => { setDuration(e.target.value) }} />
                                    </div>

                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={input1}
                                            onChange={(e) => setInput1(e.target.value)} ></textarea>
                                        <label for="floatingTextarea2">Info 1</label>

                                    </div>
                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={input2}
                                            onChange={(e) => setInput2(e.target.value)} ></textarea>
                                        <label for="floatingTextarea2">Info 2</label>

                                    </div>
                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={input3}
                                            onChange={(e) => setInput3(e.target.value)} ></textarea>
                                        <label for="floatingTextarea2">Info 3</label>

                                    </div>
                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={input4}
                                            onChange={(e) => setInput4(e.target.value)} ></textarea>
                                        <label for="floatingTextarea2">Info 4</label>

                                    </div>
                                    <div className="form-floating  w-75 my-2">
                                        <textarea className="form-control" id="floatingTextarea2" style={{ height: "6rem", resize: "none" }}
                                            value={perk}
                                            onChange={(e) => setPerk(e.target.value)} ></textarea>
                                        <label for="floatingTextarea2">Perk </label>

                                    </div>

                                </div>


                                <input type="button" value="Add Course" className="btn btn-primary" style={{ margin: "5.5rem 0rem 0rem 22rem", position: "relative", top: "-5rem" }} onClick={createCourseHandler} />
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>


            <div className="container1 ">
                <div className="row  row-cols-sm-2 row-cols-md-2 g-2">
                    {courses &&
                        courses.map(
                            (currentCourse, index) => {
                                return (
                                    <div className="col card1" key={index}  >

                                        <div className="card shadow-sm" >
                                            <Delete style={{ position: "absolute", color: "red", right: "1rem", top: "1rem", cursor: "pointer" }} onClick={() => {
                                                deleteCourseHandler(
                                                    currentCourse.courseSlug
                                                )
                                            }} />
                                            {/* <svg className="bd-placeholder-img card-img-top" width="100%" height="225" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c" /><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg> */}
                                            <div>
                                                <img
                                                    width="100%" height="50%"
                                                    src={currentCourse.featureImage}
                                                    alt="thumbanail"
                                                />
                                            </div>
                                            <div className="card-body">

                                                <p className="card-text">{currentCourse.courseName}</p>
                                                <p className="card-text">{currentCourse.courseDescription}</p>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-group">


                                                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => {
                                                            redirectHandler(
                                                                currentCourse.courseSlug,
                                                                currentCourse._id
                                                            )
                                                        }}>Add</button>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </div>

    )
}

