import React from "react";
import { Typography, Button, FormLabel } from "@mui/material"
import "./styles.css"
import { LoginLogic } from "./login.logic";
// import illustration from "../../images/login-illustration.jpg";

//define initial state for login form

const Login = () => {

    const { email, setEmail, password, setPassword, loginHander } =
        LoginLogic();
    return (
        // Login Form
        <div className="container">
            <div className="paper">
                <div
                    className="form"
                    autoComplete="off"
                >
                    <Typography variant="h4" className="text">
                        Login As Admin
                    </Typography>

                    <div className="group">
                        <FormLabel className="label">Email:</FormLabel>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            name="email"
                            className="box"
                            required
                            type="email"
                        />
                    </div>
                    <div className="group">
                        <FormLabel className="label">Password:</FormLabel>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            name="password"
                            className="box"
                            required
                            type="password"
                        />
                    </div>

                    <Button
                        onClick={loginHander}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="submit"
                    >
                        Login
                    </Button>
                </div>
            </div>
           
        </div>
    );
};
export default Login;
