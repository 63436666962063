import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
//IMPORT REDUX
import { getAllModule, createNewModule, deleteModule } from '../../redux/actions/courseAction'
import { useDispatch, useSelector } from 'react-redux'

export const ModuleLogic = () => {
    const dispatch = useDispatch()
    const { courseName,id } = useParams()
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [open, setOpen] = useState(false);

    const { modules } = useSelector((state) => state.getAllModule)


    const createModuleHandler = () => {
        setOpen(false)
        if (title) {
            dispatch(createNewModule(title, id, courseName))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
            })
        }
    }
    const redirectHandler = async (moduleSlug,id) => {

        navigate(`/${courseName}/module/${moduleSlug}/chapter/${id}`)
    }
    const deleteModuleHandler = async (moduleSlug) => {
        dispatch(deleteModule(moduleSlug))
    }
    useEffect(() => {
        dispatch(getAllModule(courseName))
    }, [dispatch, courseName])

    return {
        open,
        setOpen,
        redirectHandler,
        deleteModuleHandler,
        title,
        setTitle,
        createModuleHandler,
        modules,
    }
}
