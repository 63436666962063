import { combineReducers } from 'redux'
import {
    createNewCourseReducer,
    createNewModuleReducer,
    createNewChapterReducer,
    getAllCourseReducer,
    getAllModuleReducer,
    getAllChapterReducer,
    deleteCourseReducer,
    deleteModuleReducer,
    deleteChapterReducer,
    updateChapterReducer
} from './courseReducer'
import {uploadImageToCloudReducer, userSigninReducer} from './adminReducer'

const rootReducer = combineReducers({
    userSignin: userSigninReducer,
    createNewCourse: createNewCourseReducer,
    createNewModule: createNewModuleReducer,
    createNewChapter: createNewChapterReducer,
    updateChapter:updateChapterReducer,
    getAllCourse: getAllCourseReducer,
    getAllModule: getAllModuleReducer,
    getAllChapter:getAllChapterReducer,
    deleteCourse: deleteCourseReducer,
    deleteModule:deleteModuleReducer,
    deleteChapter: deleteChapterReducer,
    uploadImageToCloud:uploadImageToCloudReducer

})

export default rootReducer
