export const GET_ALL_COURSES_REQUEST = 'GET_ALL_COURSES_REQUEST'
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS'
export const GET_ALL_COURSES_FAILED = 'GET_ALL_COURSES_FAILED'

export const GET_ALL_MODULE_REQUEST = 'GET_ALL_MODULE_REQUEST'
export const GET_ALL_MODULE_SUCCESS = 'GET_ALL_MODULE_SUCCESS'
export const GET_ALL_MODULE_FAILED = 'GET_ALL_MODULE_FAILED'

export const GET_ALL_CHAPTER_REQUEST = 'GET_ALL_CHAPTER_REQUEST'
export const GET_ALL_CHAPTER_SUCCESS = 'GET_ALL_CHAPTER_SUCCESS'
export const GET_ALL_CHAPTER_FAILED = 'GET_ALL_CHAPTER_FAILED'

export const GET_SINGLE_COURSE_REQUEST = 'GET_SINGLE_COURSE_REQUEST'
export const GET_SINGLE_COURSE_SUCCESS = 'GET_SINGLE_COURSE_SUCCESS'
export const GET_SINGLE_COURSE_FAILED = 'GET_SINGLE_COURSE_FAILED'

export const CREATE_COURSE_REQUEST= 'CREATE_COURSE_REQUEST'
export const CREATE_COURSE_SUCCESS= 'CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAILED= 'CREATE_COURSE_FAILED'

export const CREATE_CHAPTER_REQUEST= 'CREATE_CHAPTER_REQUEST'
export const CREATE_CHAPTER_SUCCESS= 'CREATE_CHAPTER_SUCCESS'
export const CREATE_CHAPTER_FAILED= 'CREATE_CHAPTER_FAILED'

export const CREATE_MODULE_REQUEST= 'CREATE_MODULE_REQUEST'
export const CREATE_MODULE_SUCCESS= 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_FAILED= 'CREATE_MODULE_FAILED'

export const DELETE_COURSE_REQUEST= 'DELETE_COURSE_REQUEST'
export const DELETE_COURSE_SUCCESS= 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAILED= 'DELETE_COURSE_FAILED'

export const DELETE_CHAPTER_REQUEST= 'DELETE_CHAPTER_REQUEST'
export const DELETE_CHAPTER_SUCCESS= 'DELETE_CHAPTER_SUCCESS'
export const DELETE_CHAPTER_FAILED= 'DELETE_CHAPTER_FAILED'

export const UPDATE_CHAPTER_REQUEST= 'UPDATE_CHAPTER_REQUEST'
export const UPDATE_CHAPTER_SUCCESS= 'UPDATE_CHAPTER_SUCCESS'
export const UPDATE_CHAPTER_FAILED= 'UPDATE_CHAPTER_FAILED'

export const DELETE_MODULE_REQUEST= 'DELETE_MODULE_REQUEST'
export const DELETE_MODULE_SUCCESS= 'DELETE_MODULE_SUCCESS'
export const DELETE_MODULE_FAILED= 'DELETE_MODULE_FAILED'
